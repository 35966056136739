import { useTranslation } from 'react-i18next'
import { Text } from '@mantine/core'
import { PaddockGroupTimeline } from '@/components/PaddockGroupTimeline'
import { UserActivityLogSummary } from '@/features/paddocks/api/paddocksApi'

type GrazingGroupHistoryProps = {
  userActivityLogs: UserActivityLogSummary
  farmId: number
  paddockGroupId: number
}

export const GrazingGroupHistory = ({
  userActivityLogs,
  paddockGroupId,
  farmId,
}: GrazingGroupHistoryProps) => {
  const { t } = useTranslation('grazing')

  return (
    <>
      <Text mt="lg" size="md" weight={700}>
        {t('history.title', 'Group History')}
      </Text>
      <PaddockGroupTimeline
        userActivityLogs={userActivityLogs}
        farmId={farmId}
        paddockGroupId={paddockGroupId}
      />
    </>
  )
}
