import { Burger, Group, Header, MediaQuery } from '@mantine/core'
import { AffixPosition, LanguagePicker } from '../i18n'
import { Logo } from '../Logo'
import { Farms } from '@/features/farms'

type AppHeaderProps = {
  toggle: () => void
  opened: boolean
}

export const AppHeader = ({ toggle, opened }: AppHeaderProps) => {
  return (
    <Header height={60} p="xs">
      <Group position="apart">
        <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
          <Burger opened={opened} size="sm" mr="xl" onClick={toggle} />
        </MediaQuery>
        <Logo />
        <Group>
          <Farms />
          <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
            <div>
              <LanguagePicker position={AffixPosition.TOP_RIGHT} />
            </div>
          </MediaQuery>
        </Group>
      </Group>
    </Header>
  )
}
