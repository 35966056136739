import { PaddockGroupBadge } from '@/components/PaddockGroupBadge'
import { PaddockGroupType } from '@/features/paddocks/types/PaddockGroup.types'
import { Card, Group, Image, Text } from '@mantine/core'
import { Link } from 'react-router-dom'
import cardBackgroundImage from '../assets/card_background.jpg'

type GrazingGroupCardProps = {
  id: number
  name: string
  type: PaddockGroupType
}

export const GrazingGroupCard = ({ id, name, type }: GrazingGroupCardProps) => {
  return (
    <Card withBorder shadow="sm" component={Link} to={`/grazing/${id}`} maw="350">
      <Card.Section>
        <Image src={cardBackgroundImage} height={170} />
      </Card.Section>

      <Card.Section withBorder p="md">
        <Group position="apart">
          <Text fz="lg" fw={600}>
            {name}
          </Text>
          <PaddockGroupBadge type={type} />
        </Group>
      </Card.Section>
    </Card>
  )
}
