export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN as string
export const APP_RELEASE = process.env.REACT_APP_APP_RELEASE as string
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY as string
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN as string
export const FIREBASE_DB_URL = process.env.REACT_APP_FIREBASE_DB_URL as string
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID as string
export const MAPBOX_STATIC_IMG_URL = 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/static'
export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string
export const MAPBOX_MAP_STYLE = 'ram-farmote/ckv8ic24p7nra15phpnmwclti'
export const NOTIFICATION_SERVICE_URL = 'http://localhost:8091'
export const MAPPING_SERVICE_URL =
  process.env.REACT_APP_MAPPING_SERVICE_URL || 'http://localhost:8092'
export const GRAZING_SERVICE_URL =
  process.env.REACT_APP_GRAZING_SERVICE_URL || 'http://localhost:8083'
export const PORTAL_API_URL = process.env.REACT_APP_PORTAL_API_URL || 'http://localhost:8080'
export const ENV = process.env.REACT_APP_ENV || process.env.NODE_ENV
export const IS_DEV_TEST = ['development', 'test'].includes(ENV)
export const DAYS_UNITL_MOWING_MAX = 42
export const ACTIVITY_LOG_URL = process.env.REACT_APP_ACTIVITY_LOG_URL as string
