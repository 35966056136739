import { PaddockGroupViewPaddocks } from '@/components/PaddockGroupPaddocks'
import { Paddock } from '@/features/paddocks/types/Paddock.types'
import { ActionIcon, Box, Button, Center, Group, Paper, Stack, Text } from '@mantine/core'
import { t } from 'i18next'
import { FaPencilAlt } from 'react-icons/fa'
import { Target } from '../../types/group.type'
import { useState } from 'react'

type OverviewProps = {
  isGrazingGroup?: boolean
  groupName: string
  targets: Target
  paddocks: Paddock[]
  saveGroup: () => void
}

export const Overview = ({
  isGrazingGroup = false,
  groupName,
  targets,
  paddocks,
  saveGroup,
}: OverviewProps) => {
  const [isSaveClicked, setSaveClicked] = useState(false)
  return (
    <>
      <Stack mb={40}>
        <Paper shadow="lg" mb="md" p={16}>
          <Group position="apart" mt="lg">
            <Text size="md" weight={700}>
              {t('overview.title', 'General')}
            </Text>
            <ActionIcon radius="xl">
              <FaPencilAlt />
            </ActionIcon>
          </Group>
          <Box mt={16}>
            <Text size="md" fw={600}>
              Group Name
            </Text>
            <Text size="sm">{groupName}</Text>
          </Box>
          <Box mt={16}>
            <Text size="md" fw={600}>
              Targets
            </Text>
            {isGrazingGroup ? (
              <>
                <Text size="sm">Pre-Grazing: {targets.quantity.pre} kg DM / ha</Text>
                <Text size="sm">Post-Grazing: {targets.quantity.post} kg DM / ha</Text>
              </>
            ) : (
              <Text size="sm">Cutting: {targets.quantity.cut} kg DM / ha</Text>
            )}
            <Text size="sm">{targets.quality} g protein per kg</Text>
          </Box>
        </Paper>
        <Paper shadow="lg" mb="md" p={16}>
          <PaddockGroupViewPaddocks paddocks={paddocks} />
        </Paper>
      </Stack>
      <Center>
        <Button
          type="submit"
          color="green.9"
          onClick={() => {
            setSaveClicked(true)
            saveGroup()
          }}
          mx="auto"
          loading={isSaveClicked}
          style={{ position: 'fixed', bottom: 8 }}
        >
          {t('create_group.save_btn', `Save group`)}
        </Button>
      </Center>
    </>
  )
}
