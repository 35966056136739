import { Affix } from '@mantine/core'
import { AffixPosition, LanguagePicker } from './LanguagePicker'

export const FloatingLanguagePicker = () => {
  return (
    <Affix position={{ bottom: 20, right: 20 }}>
      <LanguagePicker position={AffixPosition.BOTTOM_RIGHT} />
    </Affix>
  )
}
