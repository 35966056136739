import { useQuery } from '@tanstack/react-query'
import { GRAZING_SERVICE_URL } from '@/config'
import { axios } from '@/lib/axios'
import { Herd } from './types'

const getHerdsByFarm = async (farmId: number) => {
  const { data } = await axios.get<Herd[]>(`${GRAZING_SERVICE_URL}/api/farms/${farmId}/herds`)
  return data
}

export const useHerds = (farmId?: string) => {
  return useQuery({
    queryKey: ['herds', farmId],
    enabled: !!farmId,
    queryFn: () => getHerdsByFarm(Number(farmId!)),
  })
}
