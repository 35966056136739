import { ACTIVITY_LOG_URL, MAPPING_SERVICE_URL, PORTAL_API_URL } from '@/config'
import { axios } from '@/lib/axios'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  UserActivityLogEvent,
  DeletableActivityLogEvent,
  UserActivityEventType,
  formatUserActivityEvent,
  DisplayableUserActivityLog,
} from '../types/PaddockGroupHistory'
import { FeatureCollection } from '@turf/turf'
import { Paddock, PaddockDto } from '../types/Paddock.types'
import { getPaddockGroups } from '@/features/paddock-groups/api/paddockGroupsApi'
import { auth } from '@/lib/firebase'
import { queryClient } from '@/lib/react-query'
import dayjs from 'dayjs'
import _ from 'lodash'
import { PaddockGroup } from '../types/PaddockGroup.types'

export const getPaddockDtos = async (farmId: number): Promise<PaddockDto[]> => {
  const token = await auth.currentUser?.getIdToken()

  const { data } = await axios.get(
    `${MAPPING_SERVICE_URL}/api/farms/${farmId}/paddocks?asOf=${new Date().toISOString()}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return data
}

export const getPaddockBoundaries = async (farmId: number): Promise<FeatureCollection> => {
  const token = await auth.currentUser?.getIdToken()

  const { data } = await axios.get(
    `${MAPPING_SERVICE_URL}/api/farms/${farmId}/paddocks/boundaries?asOf=${new Date().toISOString()}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return data
}

export const usePaddockBoundaries = (farmId?: string) => {
  return useQuery({
    enabled: !!farmId,
    queryKey: ['paddock-boundaries', farmId],
    queryFn: () => getPaddockBoundaries(parseInt(farmId!)),
  })
}

export const usePaddocks = (farmId?: string) => {
  return useQuery({
    enabled: !!farmId,
    queryKey: ['paddocks', farmId],
    queryFn: async () => {
      const groups = await getPaddockGroups(parseInt(farmId!))
      return groups.flatMap((g) => g.paddocks)
    },
  })
}

export type UserActivityLogSummary = {
  events: DisplayableUserActivityLog[]
  hasMore: boolean
  currentPage: number
  paddocks: Paddock[]
  paddockGroups: PaddockGroup[]
}

export type UserActivityLogResponse = {
  hasMore: boolean
  currentPage: number
  events: UserActivityLogEvent[]
}

const eventPageSize = 10

const getUserActivityLogsForZonesAndPaddocks = async (
  pageNumber: number,
  zoneIds?: number[],
  paddockIds?: number[]
): Promise<UserActivityLogResponse> => {
  if ((zoneIds ?? paddockIds ?? []).length === 0)
    return { events: [], hasMore: false, currentPage: 0 }

  const filterBy = {
    $or: zoneIds!
      .map<Record<string, string>>((zId) => ({ 'payload.zoneId': `${zId}` }))
      .concat(paddockIds!.map((pId) => ({ 'payload.paddockId': `${pId}` }))),
  }

  const token = await auth.currentUser?.getIdToken()

  const {
    data: { result },
  } = await axios.get(
    `${ACTIVITY_LOG_URL}/api/user_activity_log?page=${pageNumber}&limit=${eventPageSize}&sortBy=-payload.end&filterBy=${JSON.stringify(
      filterBy
    )}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

  return { events: result, currentPage: pageNumber, hasMore: result.length === eventPageSize }
}

export const paddockUserActivityQueryKey = (paddockGroupId?: number) => [
  'paddock-user-activity-log',
  ...(paddockGroupId ? [paddockGroupId] : []),
]

function _isOfElement<T, U extends T>(from: T[], guard: (_t: T) => _t is U): from is U[] {
  return from.every(guard)
}

function _isOfEventType<U extends UserActivityEventType>(
  from: UserActivityLogEvent[],
  eventType: U
): from is (UserActivityLogEvent & { eventType: U })[] {
  return _isOfElement(
    from,
    (evt): evt is UserActivityLogEvent & { eventType: U } => evt.eventType === eventType
  )
}

function reconcile(events: UserActivityLogEvent[]): DeletableActivityLogEvent[] {
  const _isDeletable = (evt: UserActivityLogEvent): evt is DeletableActivityLogEvent =>
    !evt.eventType.toLowerCase().endsWith('deleted')
  const [deletable, _deleted] = _.partition(events, _isDeletable)

  const _matchingId = (evt: UserActivityLogEvent) => {
    switch (evt.eventType) {
      case 'com.farmote.api.PaddockMoveDeleted':
      case 'com.farmote.api.PaddockMoved':
        return evt.payload.reference
      case 'com.farmote.api.ZoneGrazeDeleted':
      case 'com.farmote.api.ZoneGrazed':
        return evt.payload.grazedGroupId
      default:
        ;((_x: never) => {
          throw Error('Unexpected event type')
        })(evt)
    }
  }

  const deleted = new Set(_deleted.map(_matchingId))

  return deletable.filter((evt) => !deleted.has(_matchingId(evt)))
}

const _mergeByType = (events: DeletableActivityLogEvent[]): DeletableActivityLogEvent => {
  if (_isOfEventType(events, 'com.farmote.api.ZoneGrazed')) {
    const ordered = _(events).orderBy((evt) => evt.payload.end, 'desc')
    return {
      ...ordered.first()!,
      payload: { ...ordered.first()!.payload, end: ordered.first()!.payload.end },
    }
  }

  if (_isOfEventType(events, 'com.farmote.api.PaddockMoved')) {
    const ordered = _(events).orderBy((evt) => evt.payload.date, 'desc')
    return {
      ...ordered.first()!,
      payload: { ...ordered.first()!.payload, date: ordered.first()!.payload.date },
    }
  }

  throw Error('Unexpected event type.')
}

export function mergeEvents(events: DeletableActivityLogEvent[]): DeletableActivityLogEvent[] {
  return _(events)
    .orderBy((evt) => {
      switch (evt.eventType) {
        case 'com.farmote.api.PaddockMoved':
          return evt.payload.date
        case 'com.farmote.api.ZoneGrazed':
          return evt.payload.end
        default:
          return ((_: never) => '')(evt)
      }
    }, 'desc')
    .groupBy((evt) => `${evt.traceParent}-${evt.payloadIdentifier}`)
    .mapValues<DeletableActivityLogEvent>(_mergeByType)
    .entries()
    .map(([_, v]) => v)
    .value()
}

export const usePaddockUserActivityLogs = (
  paddockGroupId: number | undefined,
  paddocks?: Paddock[],
  paddockGroups?: PaddockGroup[]
) => {
  return useQuery({
    enabled: !!paddockGroupId && !!paddockGroups && !!paddocks,
    queryKey: paddockUserActivityQueryKey(paddockGroupId),
    queryFn: async () => {
      const paddockGroup = queryClient.getQueryData<PaddockGroup>(['paddock-group', paddockGroupId])

      const zoneIds = paddockGroup?.paddocks.map((p) => p.zoneId!) ?? []
      const paddockIds = paddockGroup?.paddocks.map((p) => p.id!) ?? []

      const eventSummary = await getUserActivityLogsForZonesAndPaddocks(1, zoneIds, paddockIds)

      return {
        ...eventSummary,
        events: formatUserActivityEvent(
          paddocks!,
          paddockGroups!
        )(mergeEvents(reconcile(eventSummary.events))),
        paddocks: paddocks!,
        paddockGroups: paddockGroups!,
      }
    },
  })
}

export const useLoadMoreActivityLogs = (paddockGroupId: number | undefined) => {
  return useMutation(async (currentSummary: UserActivityLogSummary) => {
    const paddockGroup = queryClient.getQueryData<PaddockGroup>(['paddock-group', paddockGroupId])

    const zoneIds = paddockGroup?.paddocks.map((p) => p.zoneId!) ?? []
    const paddockIds = paddockGroup?.paddocks.map((p) => p.id!) ?? []
    const eventsNew = await getUserActivityLogsForZonesAndPaddocks(
      currentSummary.currentPage + 1,
      zoneIds,
      paddockIds
    )

    queryClient.setQueryData(paddockUserActivityQueryKey(paddockGroupId), {
      ...currentSummary,
      events: (currentSummary ?? { events: [] as DisplayableUserActivityLog[] }).events.concat(
        formatUserActivityEvent(
          currentSummary?.paddocks!,
          currentSummary?.paddockGroups!
        )(mergeEvents(reconcile(eventsNew.events)))
      ),
      currentPage: eventsNew.currentPage,
      hasMore: eventsNew.hasMore,
    })
  })
}

const deleteUserActivityLogs = async (farmId: number, evt: DeletableActivityLogEvent) => {
  const token = await auth.currentUser?.getIdToken()

  const deleteEventUrl = (() => {
    switch (evt.eventType) {
      case 'com.farmote.api.PaddockMoved':
        return `paddock-groups/paddock/${evt.payload.paddockId}/${
          evt.payload.paddockGroupId
        }/from/${dayjs(evt.payload.date).toISOString()}/farm/${farmId}`

      case 'com.farmote.api.ZoneGrazed':
        return `zone-grazing/farm/${farmId}/zone/${evt.payload.zoneId}/grazed-group/${evt.payload.grazedGroupId}`
    }
  })()

  return await axios.delete(`${PORTAL_API_URL}/${deleteEventUrl}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const useDeletePaddockUserActivityLogs = (farmId: number, paddockGroupId: number) => {
  return useMutation(async (evt: DeletableActivityLogEvent) => {
    await deleteUserActivityLogs(farmId, evt)

    queryClient.setQueryData(
      paddockUserActivityQueryKey(paddockGroupId),
      (previousSummary?: UserActivityLogSummary) =>
        previousSummary
          ? {
              ...previousSummary,
              events:
                previousSummary.events.filter((_evt) => _evt.event.eventId !== evt.eventId) ??
                ([] as UserActivityLogEvent[]),
            }
          : previousSummary
    )
  })
}
