import { Layout } from '@/components/Layout'
import { ActionTitleBar } from '@/components/TitleBar'
import { useUserContext } from '@/providers/user'
import { Container, SimpleGrid } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useGrazingGroups } from '../api/grazingApi'
import { GrazingGroupCard } from '../components/GrazingGroupCard'
import Background from '../assets/Grazing.jpg'

export const ListGrazingGroupsPage = () => {
  const { t } = useTranslation('grazing')
  const { farmId } = useUserContext()
  const { data, isLoading } = useGrazingGroups(farmId!)

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <Layout backgroundImg={Background}>
      <Container mt="lg">
        <ActionTitleBar title={t('list.title', 'Grazing groups')} destination="/grazing/new" />

        <SimpleGrid
          breakpoints={[
            { minWidth: 'sm', cols: 1 },
            { minWidth: 'md', cols: 2 },
          ]}
        >
          {data?.map((gg) => (
            <GrazingGroupCard key={gg.id} {...gg} />
          ))}
        </SimpleGrid>
      </Container>
    </Layout>
  )
}
