import { Image, Menu, UnstyledButton } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Languages } from './images'

export enum AffixPosition {
  'BOTTOM_RIGHT',
  'TOP_RIGHT',
}

interface LanguagePickerProps {
  position: AffixPosition
}

export const LanguagePicker = ({ position }: LanguagePickerProps) => {
  const { i18n, t } = useTranslation('common')
  const menuPosition = position === AffixPosition.BOTTOM_RIGHT ? 'top-end' : 'bottom-end'

  return (
    <Menu withArrow trigger="hover" openDelay={100} closeDelay={400} position={menuPosition}>
      <Menu.Target>
        <UnstyledButton>
          <Image radius={28} src={Languages[i18n.language]} width={28} height={28} />
        </UnstyledButton>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          icon={<Image radius={18} src={Languages.en} width={18} height={18} />}
          onClick={() => i18n.changeLanguage('en')}
        >
          {t('language_picker.en', 'English')}
        </Menu.Item>
        <Menu.Item
          icon={<Image radius={18} src={Languages.nl} width={18} height={18} />}
          onClick={() => i18n.changeLanguage('nl')}
        >
          {t('language_picker.nl', 'Dutch')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
