import React, { Suspense } from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import { Loader, MantineProvider } from '@mantine/core'
import { BrowserRouter } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import * as Sentry from '@sentry/react'
import { ErrorFallback } from './error'
import { auth } from '@/lib/firebase'
import { Notifications } from '@mantine/notifications'
import { queryClient } from '@/lib/react-query'
import { UserContextProvider } from './user'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

type AppProviderProps = {
  children: React.ReactNode
}

export const Loading = () => <Loader />

export const AppProvider = ({ children }: AppProviderProps) => {
  const [_, loading] = useAuthState(auth)

  if (loading) {
    return <Loading />
  }

  return (
    <Suspense fallback={<Loading />}>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <MantineProvider withGlobalStyles withNormalizeCSS>
          <Notifications />
          <QueryClientProvider client={queryClient}>
            <UserContextProvider>
              <BrowserRouter>{children}</BrowserRouter>
            </UserContextProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </MantineProvider>
      </Sentry.ErrorBoundary>
    </Suspense>
  )
}
