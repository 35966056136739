import { PaddockGroupDto, PaddockGroupType } from '../types/PaddockGroup.types'
import { PaddockDto } from '../types/Paddock.types'
import { FeatureCollection, Polygon } from '@turf/turf'
import { Paddock } from '../types/Paddock.types'
import { getTotalAreaOfPolygons } from '@/utils/areaCalculator'

export const buildPaddockGroupsWithPaddocks = (
  paddockGroupDtos: PaddockGroupDto[],
  paddockDtos: PaddockDto[],
  paddockBoundaries: FeatureCollection
) => {
  const findBoundary = (paddockId: number) =>
    paddockBoundaries.features.find((b: any) => b.properties.paddockId === paddockId)
      ?.geometry as Polygon

  const getType = (isActive: boolean, isCuttingGroup: boolean) => {
    if (!isActive) {
      return PaddockGroupType.OutOfRotation
    }
    return isCuttingGroup ? PaddockGroupType.Cutting : PaddockGroupType.Grazing
  }

  const filterPaddocks = (paddockDtos: PaddockDto[], paddockGroupId: number): Paddock[] =>
    paddockDtos
      .filter((p) => p.paddockGroupId === paddockGroupId)
      .map(
        (pd) =>
          ({
            id: pd.paddockId,
            name: pd.paddockName,
            zoneId: pd.zoneId,
            zoneName: pd.zoneName,
            boundary: findBoundary(pd.paddockId),
            alreadyMoved: pd.alreadyMoved,
          } as Paddock)
      )

  const getTotalArea = (paddocks: Paddock[]) => {
    const polygons = paddocks.flatMap((p) => p.boundary)
    return getTotalAreaOfPolygons(polygons)
  }

  return paddockGroupDtos.map((d) => {
    const paddocks = filterPaddocks(paddockDtos, d.groupId)
    return {
      id: d.groupId,
      name: d.groupName,
      type: getType(d.active, d.isCuttingGroup),
      paddocks,
      totalArea: getTotalArea(paddocks),
      targets: {
        pre: d.preTarget,
        post: d.postTarget,
        cut: d.cutTarget,
      },
    }
  })
}
