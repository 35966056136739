import { PaddockGroupType } from '@/features/paddocks/types/PaddockGroup.types'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { auth } from '@/lib/firebase'
import { getPaddockGroups } from '@/features/paddock-groups/api/paddockGroupsApi'
import { PORTAL_API_URL } from '@/config'
import { queryClient } from '@/lib/react-query'
import { paddockUserActivityQueryKey } from '@/features/paddocks/api/paddocksApi'

export const useGrazingGroups = (farmId: string) => {
  return useQuery({
    enabled: !isNaN(Number(farmId)),
    queryKey: ['grazing-groups', farmId],
    queryFn: async () => {
      const groups = await getPaddockGroups(Number(farmId))

      return groups.filter((g) => g.type === PaddockGroupType.Grazing)
    },
  })
}

export type ReportGrazingData = {
  zone_id: number
  zone_name: string
  begin: string
  end: string
  dry_matter: number
}

const reportGrazing = async (farmId: number, values: ReportGrazingData[]): Promise<number> => {
  const token = await auth.currentUser?.getIdToken()
  const { data } = await axios.post(
    `${PORTAL_API_URL}/zone-grazing/farm/${farmId}`,
    {
      reportedGrazings: values,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return data
}

export const useReportGrazing = (farmId: number) => {
  return useMutation({
    mutationFn: (data: ReportGrazingData[]) => reportGrazing(farmId, data),
    onSuccess: async () => {
      queryClient.refetchQueries(['paddock-group-growths', 'paddock-growths'])

      queryClient.invalidateQueries({
        predicate: (k) => paddockUserActivityQueryKey().includes(k.queryKey[0] as string),
      })
    },
  })
}
