import { SimpleLayout } from '@/components/Layout'
import { AddName } from '@/features/paddock-groups/components/create-steps/AddName'
import { AddTargets } from '@/features/paddock-groups/components/create-steps/AddTargets'
import { Overview } from '@/features/paddock-groups/components/create-steps/Overview'
import { Paddock } from '@/features/paddocks/types/Paddock.types'
import { NewGroupContext } from '@/providers/newCuttingGroup'
import { useUserContext } from '@/providers/user'
import { Container } from '@mantine/core'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PaddockSelectionPage } from '@/features/paddock-groups/components/create-steps/PaddockSelection'
import { DefaultTarget, Target } from '@/features/paddock-groups/types/group.type'
import {
  useAddPaddocksToGroup,
  useCreateGroup,
} from '@/features/paddock-groups/api/paddockGroupsApi'

export const CreateGrazingGroupPage = () => {
  const { farmId } = useUserContext()
  const navigate = useNavigate()
  const [currentStep, setCurrentStep] = useState(1)
  const [groupName, setGroupName] = useState('')
  const [paddocks, setPaddocks] = useState<Paddock[]>([])
  const [target, setTarget] = useState<Target>(DefaultTarget)
  const createGrazingGroupMutation = useCreateGroup(farmId)
  const addPaddocksToGroupMutation = useAddPaddocksToGroup(farmId)

  const saveGroup = async () => {
    const group = await createGrazingGroupMutation.mutateAsync({
      name: groupName,
      target,
    })
    await addPaddocksToGroupMutation.mutateAsync({
      zoneGroupId: group.groupId,
      paddockIds: paddocks.map((p) => p.id),
    })
    navigate('/grazing')
  }

  const stepComponent = (step: number) => {
    switch (step) {
      case 1:
        return (
          <AddName
            onNextClick={(name: string) => {
              setGroupName(name)
              setCurrentStep(currentStep + 1)
            }}
          />
        )
      case 2:
        return (
          <PaddockSelectionPage
            onNextClick={(selectedPaddocks: Paddock[]) => {
              setPaddocks(selectedPaddocks)
              setCurrentStep(currentStep + 1)
            }}
          />
        )
      case 3:
        return (
          <AddTargets
            isGrazingGroup
            onNextClick={(target: Target) => {
              setTarget(target)
              setCurrentStep(currentStep + 1)
            }}
          />
        )
      default:
        return (
          <Overview
            isGrazingGroup
            groupName={groupName}
            targets={target!}
            paddocks={paddocks}
            saveGroup={saveGroup}
          />
        )
    }
  }

  const title = groupName.length === 0 ? 'Create Group' : groupName

  const onBackClick = () => {
    if (currentStep === 1) {
      navigate('/cutting')
    } else {
      setCurrentStep(currentStep - 1)
    }
  }

  return (
    <SimpleLayout title={title} rightIcon={<></>} backClick={onBackClick}>
      <NewGroupContext.Provider
        value={{
          groupName,
          paddocks,
          target,
        }}
      >
        <Container size="xs">{stepComponent(currentStep)}</Container>
      </NewGroupContext.Provider>
    </SimpleLayout>
  )
}
