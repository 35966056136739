import { MAPBOX_ACCESS_TOKEN, MAPBOX_STATIC_IMG_URL } from '@/config'
import { useQuery } from '@tanstack/react-query'
import { Geometry, featureCollection, feature, center } from '@turf/turf'

async function getMapboxStaticImage(
  boundary: Geometry,
  options: MapboxStaticImageOptions
): Promise<string> {
  const size = `${options.width}x${options.height}`
  const latLngCenter = center(boundary)?.geometry?.coordinates?.join(',')
  const overlay = encodeURIComponent(JSON.stringify(featureCollection([feature(boundary)])))
  const imageUrl = `${MAPBOX_STATIC_IMG_URL}/geojson(${overlay})/${latLngCenter},${options.zoom},${options.bearing},${options.pitch}/${size}?access_token=${MAPBOX_ACCESS_TOKEN}`
  return imageUrl
}

interface MapboxStaticImageOptions {
  width: number
  height: number
  zoom: number
  bearing: number
  pitch: number
}

export const useMapboxStaticImage = (boundary: Geometry) => {
  return useQuery({
    queryKey: ['boundary', boundary],
    queryFn: () =>
      getMapboxStaticImage(boundary, { width: 61, height: 72, zoom: 12, bearing: 0, pitch: 0 }),
  })
}
