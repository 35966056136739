import { useRef } from 'react'
import { PaddockGrowthDisplayable } from '@/features/api/types/Growth.types'
import { Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { CombinedGraph } from './CombinedGraph'
import { QuantityTarget } from '@/features/paddock-groups/types/group.type'
import { CoverDatum } from '@/features/paddock-groups/types/graph.types'

type RecommendedGrazingGraphProps = {
  growths: PaddockGrowthDisplayable[]
  targets: QuantityTarget
}

export const RecommendedGrazingGraph = ({ growths, targets }: RecommendedGrazingGraphProps) => {
  const { t } = useTranslation('paddock-group')

  const dataWithLabels = useRef<CoverDatum[]>(
    growths.map((i) => ({
      label: i.zone_name!,
      cover: i.cover,
    }))
  )

  return (
    <>
      <Text size="lg" weight={600}>
        {t('recommended.title', 'Recommended paddock order')}
      </Text>
      <CombinedGraph
        dataWithLabels={dataWithLabels.current}
        targets={targets}
        growthsLength={growths.length}
      />
    </>
  )
}
