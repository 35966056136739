import { Group, Title, UnstyledButton } from '@mantine/core'
import { FaPlus } from 'react-icons/fa'
import { Link } from 'react-router-dom'

type ActionTitleBarProps = {
  title: string
  destination: string
}

export const ActionTitleBar = ({ title, destination }: ActionTitleBarProps) => {
  return (
    <Group position="apart">
      <Title size="h3" mb="md">
        {title}
      </Title>
      <UnstyledButton component={Link} to={destination}>
        <FaPlus />
      </UnstyledButton>
    </Group>
  )
}
