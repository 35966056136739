import { MetricCard } from '@/components/MetricCard'
import { GroupGrowth } from '@/features/api/types/Growth.types'
import { Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

type PaddockGroupMetricsProps = {
  growths: GroupGrowth
}

export const PaddockGroupMetrics = ({ growths }: PaddockGroupMetricsProps) => {
  const { t } = useTranslation('paddock-group')

  const todaysYield = growths.todaysYield
  const diffYieldSinceLast = growths.diffYieldSinceLast
  const todaysGrowth = growths.todaysGrowth
  const diffGrowthSinceLast = growths.diffGrowthSinceLast

  return (
    <>
      <Flex gap="sm" justify="center" direction="row" wrap="wrap">
        <MetricCard
          title={t('metrics.yield_title', 'Current yield')}
          value={todaysYield}
          unit="kg DM/ha"
          variation={{
            value: diffYieldSinceLast,
            label: t('metrics.yield_variation_label', 'Since last measurement'),
          }}
        />
        <MetricCard
          title={t('metrics.growth_title', '3 days Growth')}
          value={todaysGrowth}
          unit="kg DM/ha/day"
          variation={{
            value: diffGrowthSinceLast,
            label: t('metrics.growth_variation_label', 'Since last measurement'),
          }}
        />
        {/* <MetricCard
          title={t('metrics.protein_title', 'Protein')}
          value={90}
          unit="g/kg"
          variation={{
            value: -10,
            label: t('metrics.protein_variation_label', 'Since last measurement'),
          }}
        />
        <MetricCard
          title={t('metrics.vem_title', 'VEM')}
          value={2300}
          unit="VEM"
          variation={{
            value: -80,
            label: t('metrics.vem_variation_label', 'Since last measurement'),
          }}
        /> */}
      </Flex>
    </>
  )
}
