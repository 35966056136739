export interface QuantityTarget {
  pre?: number
  post?: number
  cut?: number
}

export interface Target {
  quantity: QuantityTarget
  quality: number
}

export const DefaultTarget: Target = { quantity: { pre: 3000, post: 1500, cut: 3000 }, quality: 90 }

export interface NewPaddockGroup {
  id: number
  farmId: number
  name: string
  target: Target
}
