import { usePaddocks } from '@/features/paddocks/api/paddocksApi'
import { PaddockListItem } from '@/features/paddocks/components/PaddockListItem'
import { Paddock } from '@/features/paddocks/types/Paddock.types'
import { useNewGroupContext } from '@/providers/newCuttingGroup'
import { useUserContext } from '@/providers/user'
import { pluralize } from '@/utils/pluralize'
import { Box, Button, Checkbox, Flex, Paper, Stack, Text } from '@mantine/core'
import { t } from 'i18next'
import { useState } from 'react'
import { some } from 'lodash'

type PaddockSelectionPageProps = {
  paddocksInGroup?: Paddock[]
  onNextClick: (selectedPaddocks: Paddock[]) => void
}

export const PaddockSelectionPage = ({
  paddocksInGroup,
  onNextClick,
}: PaddockSelectionPageProps) => {
  const { farmId } = useUserContext()
  const { data: paddocks } = usePaddocks(farmId)
  const { paddocks: existingPaddocks } = useNewGroupContext()
  const [selected, setSelected] = useState<Paddock[]>([
    ...existingPaddocks,
    ...(paddocksInGroup ?? []),
  ])

  const onSelectChange = (p: Paddock) => {
    const changedSelection = [...selected]
    const paddockFound = changedSelection.find((cs) => cs.id === p.id)
    if (paddockFound) {
      changedSelection.splice(changedSelection.indexOf(paddockFound), 1)
    } else {
      changedSelection.push(p)
    }
    setSelected(changedSelection)
  }

  if (!paddocks || !existingPaddocks) {
    return <></>
  }

  return (
    <Stack align="center">
      <Text>What paddocks would you like to add to this group?</Text>
      <Box mb={48}>
        {paddocks.map((p) => (
          <Paper
            key={p.id}
            shadow="xs"
            mb="md"
            style={{ width: 350, cursor: !p.alreadyMoved ? 'pointer' : 'default' }}
            onClick={() => !p.alreadyMoved && onSelectChange(p)}
          >
            <Flex>
              <Box style={{ flex: 1 }}>
                <PaddockListItem
                  name={p.name}
                  boundary={p.boundary}
                  key={p.id}
                  alreadyMoved={p.alreadyMoved}
                />
              </Box>
              <Checkbox
                label=""
                style={{ padding: 16 }}
                onChange={() => onSelectChange(p)}
                checked={some(selected, { id: p.id })}
                color="pink.6"
                disabled={p.alreadyMoved}
              />
            </Flex>
          </Paper>
        ))}
      </Box>
      <Button
        type="submit"
        color="green.9"
        disabled={selected.length === 0}
        onClick={() => onNextClick(selected)}
        mx="auto"
        style={{ position: 'sticky', bottom: 8 }}
      >
        {t(
          'create_group.save_btn',
          `Add ${selected.length} ${pluralize(selected.length, 'paddock')} to group`
        )}
      </Button>
    </Stack>
  )
}
