import { IconChevronRight, IconChevronLeft } from '@tabler/icons'
import { UnstyledButton, Group, Text, Box, useMantineTheme, Menu } from '@mantine/core'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '@/lib/firebase'
import { UserAvatar } from '../UserAvatar'
import { useTranslation } from 'react-i18next'
import { useSignOut } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'

export const User = () => {
  const theme = useMantineTheme()
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [user] = useAuthState(auth)
  const [signOut] = useSignOut(auth)

  const handleLogout = async () => {
    await signOut()
    navigate('/auth/login')
  }

  return (
    <Box
      sx={{
        paddingTop: theme.spacing.sm,
        borderTop: `1px solid ${
          theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
        }`,
      }}
    >
      <Menu position="top-end">
        <Menu.Target>
          <UnstyledButton
            sx={{
              display: 'block',
              width: '100%',
              padding: theme.spacing.xs,
              borderRadius: theme.radius.sm,
              color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

              '&:hover': {
                backgroundColor:
                  theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
              },
            }}
          >
            <Group>
              <UserAvatar email={user?.email || ''} photoUrl={user?.photoURL} />
              <Box sx={{ flex: 1 }}>
                <Text size="sm" weight={500} lineClamp={1}>
                  {user?.displayName}
                </Text>
                <Text color="dimmed" size="xs" lineClamp={1}>
                  {user?.email}
                </Text>
              </Box>

              {theme.dir === 'ltr' ? <IconChevronRight size={18} /> : <IconChevronLeft size={18} />}
            </Group>
          </UnstyledButton>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item onClick={handleLogout}>{t('navbar.logout', 'Logout')}</Menu.Item>
          <Menu.Item>{t('navbar.preferences', 'User preferences')}</Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Box>
  )
}
