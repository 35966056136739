import { MAPPING_SERVICE_URL } from '@/config'
import { NewPaddockGroup } from '@/features/paddock-groups/types/group.type'
import { buildPaddockGroupsWithPaddocks } from '@/features/paddocks/api/paddockGroupsBuilder'
import {
  getPaddockBoundaries,
  getPaddockDtos,
  paddockUserActivityQueryKey,
} from '@/features/paddocks/api/paddocksApi'
import {
  PaddockGroup,
  PaddockGroupDto,
  PaddockGroupType,
} from '@/features/paddocks/types/PaddockGroup.types'
import { auth } from '@/lib/firebase'
import { queryClient } from '@/lib/react-query'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'

export const getPaddockGroups = async (farmId: number): Promise<PaddockGroup[]> => {
  const paddockDtos = await getPaddockDtos(farmId)
  const paddockGroupDtos = await getPaddockGroupDtos(farmId)
  const paddockBoundaries = await getPaddockBoundaries(farmId)
  const paddockGroupsWithPaddocks = buildPaddockGroupsWithPaddocks(
    paddockGroupDtos,
    paddockDtos,
    paddockBoundaries
  )
  return paddockGroupsWithPaddocks
}

const getPaddockGroupDtos = async (farmId: number): Promise<PaddockGroupDto[]> => {
  const token = await auth.currentUser?.getIdToken()

  const { data } = await axios.get(`${MAPPING_SERVICE_URL}/api/farms/${farmId}/paddock-groups`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return data
}

export type CreatePaddockGroupData = Omit<NewPaddockGroup, 'id' | 'farmId'>

export const createPaddockGroup = async (
  farmId: number,
  values: CreatePaddockGroupData,
  isCuttingGroup = false
): Promise<PaddockGroupDto> => {
  const token = await auth.currentUser?.getIdToken()
  const { data } = await axios.post(
    `${MAPPING_SERVICE_URL}/api/farms/${farmId}/paddock-groups`,
    {
      name: values.name,
      isCuttingGroup: isCuttingGroup,
      target: {
        ...values.target,
        quantity: {
          cut: isCuttingGroup ? values.target.quantity.cut! : null,
          post: !isCuttingGroup ? values.target.quantity.post! : null,
          pre: !isCuttingGroup ? values.target.quantity.pre! : null,
        },
      },
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return data
}

const addPaddocksToGroup = async (
  farmId: number,
  zoneGroupId: number,
  values: number[]
): Promise<number> => {
  const token = await auth.currentUser?.getIdToken()
  const { data } = await axios.post(
    `${MAPPING_SERVICE_URL}/api/farms/${farmId}/paddock-groups/${zoneGroupId}/paddocks`,
    values,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return data.groupId
}

export const useAddPaddocksToGroup = (farmId?: string, paddockGroupId?: string) => {
  return useMutation({
    mutationFn: (data: { zoneGroupId: number; paddockIds: number[] }) =>
      addPaddocksToGroup(Number(farmId), Number(data.zoneGroupId), data.paddockIds),
    onSuccess: async () => {
      const groups = await getPaddockGroups(Number(farmId))
      const paddockGroup = groups.find((pg) => pg.id === Number(paddockGroupId))
      const cuttingGroups = groups.filter((g) => g.type === PaddockGroupType.Cutting)
      const grazingGroups = groups.filter((g) => g.type === PaddockGroupType.Grazing)
      queryClient.setQueryData(['paddock-groups', farmId], groups)
      queryClient.setQueryData(['paddock-group', Number(paddockGroupId)], paddockGroup)
      queryClient.setQueryData(['cutting-groups', farmId], cuttingGroups)
      queryClient.setQueryData(['grazing-groups', farmId], grazingGroups)
      queryClient.refetchQueries(['paddock-group-growths', 'paddock-growths'])

      queryClient.invalidateQueries({
        predicate: (k) => paddockUserActivityQueryKey().includes(k.queryKey[0] as string),
      })
    },
  })
}

const removePaddocksFromGroup = async (
  farmId: number,
  zoneGroupId: number,
  values: number[]
): Promise<number> => {
  const token = await auth.currentUser?.getIdToken()
  const { data } = await axios.delete(
    `${MAPPING_SERVICE_URL}/api/farms/${farmId}/paddock-groups/${zoneGroupId}/paddocks`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: values,
    }
  )
  return data.groupId
}

export const useRemovePaddocksFromGroup = (farmId?: string, zoneGroupId?: string) => {
  return useMutation({
    mutationFn: (data: { zoneGroupId: number; paddockIds: number[] }) =>
      removePaddocksFromGroup(Number(farmId), Number(data.zoneGroupId), data.paddockIds),
    onSuccess: async () => {
      const groups = await getPaddockGroups(Number(farmId))
      const paddockGroup = groups.find((pg) => pg.id === Number(zoneGroupId))
      queryClient.setQueryData(['paddock-groups', farmId], groups)
      queryClient.setQueryData(['paddock-group', Number(zoneGroupId)], paddockGroup)

      queryClient.invalidateQueries({
        predicate: (k) => paddockUserActivityQueryKey().includes(k.queryKey[0] as string),
      })
    },
  })
}

export const usePaddockGroups = (farmId?: string) => {
  return useQuery({
    enabled: !!farmId,
    queryKey: ['paddock-groups', farmId],
    queryFn: () => getPaddockGroups(parseInt(farmId!)),
  })
}

export const usePaddockGroup = (paddockGroupId: number, farmId?: string) => {
  return useQuery({
    enabled: !!farmId,
    queryKey: ['paddock-group', paddockGroupId],
    queryFn: async () => {
      const groups = await getPaddockGroups(Number(farmId))
      const paddockGroup = groups.find((pg) => pg.id === Number(paddockGroupId))
      const cuttingGroups = groups.filter((g) => g.type === PaddockGroupType.Cutting)
      const grazingGroups = groups.filter((g) => g.type === PaddockGroupType.Grazing)
      queryClient.setQueryData(['paddock-groups', farmId], groups)
      queryClient.setQueryData(['paddock-group', Number(paddockGroupId)], paddockGroup)
      queryClient.setQueryData(['cutting-groups', farmId], cuttingGroups)
      queryClient.setQueryData(['grazing-groups', farmId], grazingGroups)
      queryClient.refetchQueries(['paddock-group-growths', 'paddock-growths'])
      return paddockGroup
    },
  })
}

export const useCreateGroup = (farmId?: string, isCuttingGroup?: boolean) => {
  return useMutation({
    mutationFn: (data: CreatePaddockGroupData) =>
      createPaddockGroup(Number(farmId), data, isCuttingGroup ?? false),
    onSuccess: async () => {
      const groups = await getPaddockGroups(Number(farmId))
      const cuttingGroups = groups.filter((g) => g.type === PaddockGroupType.Cutting)
      const grazingGroups = groups.filter((g) => g.type === PaddockGroupType.Grazing)
      queryClient.setQueryData(['paddock-groups', farmId], groups)
      queryClient.setQueryData(['cutting-groups', farmId], cuttingGroups)
      queryClient.setQueryData(['grazing-groups', farmId], grazingGroups)
      queryClient.refetchQueries(['paddock-group-growths', 'paddock-growths'])
    },
  })
}
