import { Chart } from 'react-chartjs-2'
import { GraphDimensions, Options } from './GraphOptions'
import { GrazingGroupGraphYAxis } from './GrazingGroupGraphYAxis'
import { QuantityTarget } from '@/features/paddock-groups/types/group.type'
import { getGraphData } from './GraphDataSet'
import { useRef } from 'react'
import { CoverDatum } from '@/features/paddock-groups/types/graph.types'

interface CombinedGraphProps {
  dataWithLabels: CoverDatum[]
  paddocksNumber?: number
  targets: QuantityTarget
  growthsLength: number
}

export const CombinedGraph = ({
  dataWithLabels,
  paddocksNumber,
  targets,
  growthsLength,
}: CombinedGraphProps) => {
  const options = useRef(Options)
  const data = getGraphData(dataWithLabels, targets, growthsLength)
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '80vw',
            justifyContent: 'center',
            padding: 20,
          }}
        >
          <div
            style={{
              width: GraphDimensions.barWidth,
            }}
          >
            <GrazingGroupGraphYAxis dataWithLabels={dataWithLabels} />
          </div>
          <div
            style={{
              maxWidth: GraphDimensions.chartWidth,
              overflowX: 'scroll',
            }}
          >
            <div
              style={{
                width: (paddocksNumber ?? growthsLength) * GraphDimensions.barWidth,
                height: GraphDimensions.chartHeight,
              }}
            >
              <Chart type="bar" data={data} options={options.current} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
