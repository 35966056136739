import { useUserContext } from '@/providers/user'
import { notifications } from '@mantine/notifications'
import { IconCheck } from '@tabler/icons'
import { doc } from '@firebase/firestore'
import { onSnapshot } from 'firebase/firestore'
import { firestore } from '@/lib/firebase'
import { useEffect } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { queryClient } from '@/lib/react-query'

const pphProcessorStatusKey = (farmId?: string) => [
  'pph-processor-status',
  ...(farmId ? [farmId] : []),
]

type ProcessingStatus = 'unknown' | 'in-progress' | 'complete' | 'reset'

export const usePphProcessStatus = (farmId?: string) => {
  return useQuery({
    enabled: !!farmId,
    queryKey: pphProcessorStatusKey(farmId!),
    queryFn: async () => {
      return 'unknown' as ProcessingStatus
    },
  })
}

const interpretStatus = (st1: ProcessingStatus, st2: ProcessingStatus): ProcessingStatus => {
  // unknown is contextual on the previous status, and is up for interpretation
  if (st2 === 'unknown' && st1 === 'in-progress') return 'complete'
  if (st2 === 'unknown') return st1
  // cannot reset if anything is in progress; complete is not settable
  if (st1 === 'in-progress') return st1
  return st2
}

export const useUpdatePphProcessorStatus = (farmId?: string) => {
  return useMutation(async (status: ProcessingStatus) => {
    queryClient.setQueryData(pphProcessorStatusKey(farmId!), (prev?: ProcessingStatus) =>
      interpretStatus(prev ?? 'unknown', status)
    )
  })
}

const DataProcessorStatus = () => {
  const { farmId } = useUserContext()

  const setNotification = (isInProgress: boolean) => {
    if (isInProgress) {
      notifications.show({
        id: 'load-data',
        loading: true,
        title: 'Loading your data',
        message: 'Data is being recalculated.',
        autoClose: false,
        withCloseButton: false,
      })
    } else {
      notifications.update({
        id: 'load-data',
        color: 'teal',
        title: 'Data was loaded',
        message: 'New data is ready when you refresh your page.',
        icon: <IconCheck size="1rem" />,
        autoClose: false,
      })
    }
  }

  const updatePphProcessStatus = useUpdatePphProcessorStatus(farmId)

  useEffect(() => {
    if (!farmId) return

    try {
      const statusRef = doc(firestore, 'pph-processor-status', farmId)
      onSnapshot(statusRef, (s) => {
        console.log(s.data())

        const isProcessing = s.data()?.status === 'IN PROGRESS'
        setNotification(isProcessing)

        updatePphProcessStatus.mutateAsync(isProcessing ? 'in-progress' : 'unknown')
      })
    } catch (ex) {
      console.log(ex)
    }
  }, [farmId])

  if (!farmId) {
    return <></>
  }

  return <></>
}

export default DataProcessorStatus
