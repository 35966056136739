import { useEffect, useState } from 'react'
import { PaddockGrowthDisplayable } from '@/features/api/types/Growth.types'
import _ from 'lodash'
import { CoverDatum } from '@/features/paddock-groups/types/graph.types'
import { QuantityTarget } from '@/features/paddock-groups/types/group.type'
import { CombinedGraph } from './CombinedGraph'

type GrazingGroupGraphProps = {
  growths: PaddockGrowthDisplayable[]
  paddockOrder: string[]
  targets: QuantityTarget
}

export const GrazingGroupGraph = ({ growths, paddockOrder, targets }: GrazingGroupGraphProps) => {
  const [dataWithLabels, setDataWithLabels] = useState<CoverDatum[]>()

  useEffect(() => {
    const filteredGrowths = growths.filter((g) => paddockOrder?.includes(g.zone_name!))
    const sortedGrowths = _.sortBy(filteredGrowths, (obj) => {
      return _.indexOf(paddockOrder, obj.zone_name)
    })
    setDataWithLabels(
      sortedGrowths.map((i) => ({
        label: i.zone_name!,
        cover: i.cover,
      }))
    )
    // eslint-disable-next-line
  }, [paddockOrder])

  if (!dataWithLabels) {
    return <></>
  }

  return (
    <CombinedGraph
      dataWithLabels={dataWithLabels}
      paddocksNumber={paddockOrder.length}
      targets={targets}
      growthsLength={growths.length}
    />
  )
}
