import { Text, Flex, Badge, Tooltip, Box } from '@mantine/core'
import { Geometry } from '@turf/turf'
import { useMapboxStaticImage } from '../../api/boundaryImgApi'

type PaddockListItemProps = {
  name: string
  boundary: Geometry
  alreadyMoved?: boolean
}

export const PaddockListItem = ({ name, boundary, alreadyMoved }: PaddockListItemProps) => {
  const { data, error, isError, isLoading } = useMapboxStaticImage(boundary)

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error! {(error as any).message}</div>
  }

  return (
    <Tooltip
      label="Please delete previous move first"
      withArrow
      disabled={!alreadyMoved}
      color="gray"
    >
      <Flex direction="row" align="center" gap="md">
        <img src={data} alt="boundary-img" />
        <Box>
          <Text fz="xl">{name}</Text>
          {alreadyMoved && <Badge color="gray">Already Moved today!</Badge>}
        </Box>
      </Flex>
    </Tooltip>
  )
}
