import { useRoutes } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { wrapUseRoutes } from '@sentry/react'
import { LandingPage } from '@/features/misc'
import { lazyImport } from '@/utils/lazyImport'
import { auth } from '@/lib/firebase'
import { GrazingRoutes } from '@/features/grazing'

const useSentryRoutes = wrapUseRoutes(useRoutes)

const { AuthRoutes } = lazyImport(() => import('@/features/auth'), 'AuthRoutes')
const { DashboardRoutes } = lazyImport(() => import('@/features/dashboard'), 'DashboardRoutes')
const { OnboardRoutes } = lazyImport(() => import('@/features/onboard'), 'OnboardRoutes')
const { ActionsRoutes } = lazyImport(() => import('@/features/actions'), 'ActionsRoutes')
const { HerdsRoutes } = lazyImport(() => import('@/features/herds'), 'HerdsRoutes')
const { CuttingRoutes } = lazyImport(() => import('@/features/cutting'), 'CuttingRoutes')
const { PaddocksRoutes } = lazyImport(() => import('@/features/paddocks'), 'PaddocksRoutes')
const { MapRoutes } = lazyImport(() => import('@/features/map'), 'MapRoutes')

export const AppRoutes = () => {
  const [user] = useAuthState(auth)
  const commonRoutes = [{ path: '/', element: <LandingPage /> }]

  const routes = !!user
    ? [
        { path: '/dashboard/*', element: <DashboardRoutes /> },
        { path: '/onboard/*', element: <OnboardRoutes /> },
        { path: '/actions/*', element: <ActionsRoutes /> },
        { path: '/herds/*', element: <HerdsRoutes /> },
        { path: '/cutting/*', element: <CuttingRoutes /> },
        { path: '/paddocks/*', element: <PaddocksRoutes /> },
        { path: '/map/*', element: <MapRoutes /> },
        { path: '/grazing/*', element: <GrazingRoutes /> },
      ]
    : [{ path: '/auth/*', element: <AuthRoutes /> }]

  return useSentryRoutes([...commonRoutes, ...routes])
}
