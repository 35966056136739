import { useNavigate } from 'react-router'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '@/lib/firebase'
import { useEffect } from 'react'

export const LandingPage = () => {
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()

  useEffect(() => {
    if (loading) {
      return
    }

    const destination = !!user ? '/dashboard' : '/auth/login'
    navigate(destination)
  }, [user, loading, navigate])

  return <></>
}
