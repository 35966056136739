import { PaddockOrder } from '@/features/grazing/api/types'
import { isEmpty } from 'lodash'

export const getOrderedPaddockNames = (paddockOrder: PaddockOrder): string[] => {
  if (isEmpty(paddockOrder)) {
    return []
  }

  return Object.entries(paddockOrder)
    .sort((a, b) => a[1] - b[1])
    .map(([key, _]) => key)
}
