import { Box, createStyles } from '@mantine/core'

const useStyles = createStyles((theme) => ({
  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}))

export const Separator = () => {
  const { classes } = useStyles()
  return <Box className={classes.section} />
}
