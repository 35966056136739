import { Paddock } from './Paddock.types'

export enum PaddockGroupType {
  Grazing = 'Grazing',
  Cutting = 'Cutting',
  OutOfRotation = 'Out of Rotation',
}

export type PaddockGroup = {
  id: number
  name: string
  type: PaddockGroupType
  paddocks: Paddock[]
  totalArea: number
  targets: {
    pre: number
    post: number
    cut: number
  }
}

export type PaddockGroupDto = {
  groupId: number
  groupName: string
  active: boolean
  isCuttingGroup: boolean
  preTarget: number
  postTarget: number
  cutTarget: number
}
