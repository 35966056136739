export const Options = {
  maintainAspectRatio: false,
  layout: {
    padding: {
      top: 10,
      bottom: 10,
    },
  },
  scales: {
    x: {
      grid: {
        drawOnChartArea: false,
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        display: false,
        stepSize: 1000,
      },
      grid: {
        drawTicks: false,
        drawBorder: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
}

export const GraphDimensions = {
  barWidth: 60,
  chartWidth: 600,
  chartHeight: 300,
}
