import { QueryClient } from '@tanstack/react-query'

const queryConfig = {
  queries: {
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
    staleTime: 15 * (60 * 1000),
    cacheTime: 20 * (60 * 1000),
  },
}

export const queryClient = new QueryClient({ defaultOptions: queryConfig })
