import { CoverDatum } from '@/features/paddock-groups/types/graph.types'
import { useRef } from 'react'
import { Bar } from 'react-chartjs-2'

type GrazingGroupGraphYAxisProps = {
  dataWithLabels: CoverDatum[]
}

export const GrazingGroupGraphYAxis = ({ dataWithLabels }: GrazingGroupGraphYAxisProps) => {
  const data = useRef({
    labels: dataWithLabels.map((d) => d.label),
    datasets: [
      {
        data: dataWithLabels.map((d) => d.cover),
      },
    ],
  })

  const options = useRef({
    maintainAspectRatio: false,
    layout: {
      padding: {
        bottom: 38.5,
      },
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
        grid: {
          drawTicks: false,
          drawOnChartArea: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1000,
        },
        afterFit: (ctx: any) => {
          ctx.width = 67
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  })

  return <Bar data={data.current} options={options.current} />
}
