import { useNewGroupContext } from '@/providers/newCuttingGroup'
import { Button, Center, SegmentedControl, Stack, Text, TextInput } from '@mantine/core'
import { t } from 'i18next'
import { useState } from 'react'
import { Target } from '../../types/group.type'

type AddTargetProps = {
  isGrazingGroup?: boolean
  onNextClick: (target: Target) => void
}

export const AddTargets = ({ isGrazingGroup = false, onNextClick }: AddTargetProps) => {
  const { target: existingTarget } = useNewGroupContext()
  const [target, setTarget] = useState<Target>(existingTarget)

  const [tab, setTab] = useState('Quantity')

  return (
    <>
      <Text size="md" fw={600}>
        What is your target?
      </Text>
      <Text size="sm">Do you cut by quality or quantity?</Text>
      <Stack mt={16}>
        <SegmentedControl data={['Quantity', 'Quality']} value={tab} onChange={setTab} />
        {tab === 'Quantity' && (
          <>
            {isGrazingGroup ? (
              <>
                <TextInput
                  type="number"
                  label="Pre-Grazing target"
                  description="kg DM / ha"
                  withAsterisk
                  value={target.quantity.pre}
                  onChange={(e) =>
                    setTarget({
                      ...target,
                      quantity: { ...target.quantity, pre: Number(e.target.value) },
                    })
                  }
                />
                <TextInput
                  type="number"
                  label="Post-Grazing target"
                  description="kg DM / ha"
                  withAsterisk
                  value={target.quantity.post}
                  onChange={(e) =>
                    setTarget({
                      ...target,
                      quantity: { ...target.quantity, post: Number(e.target.value) },
                    })
                  }
                />
              </>
            ) : (
              <TextInput
                type="number"
                label="Cutting target"
                description="kg DM / ha"
                withAsterisk
                value={target.quantity.cut}
                onChange={(e) =>
                  setTarget({
                    ...target,
                    quantity: { ...target.quantity, cut: Number(e.target.value) },
                  })
                }
              />
            )}
          </>
        )}
        {tab === 'Quality' && (
          <TextInput
            type="number"
            placeholder={`${target.quantity}`}
            label="Quality target"
            description="g protein per kg"
            withAsterisk
            value={target.quality}
            onChange={(e) => setTarget({ ...target, quality: Number(e.target.value) })}
          />
        )}
      </Stack>
      <Center>
        <Button
          type="submit"
          color="green.9"
          disabled={target?.quantity.pre === 0 && target?.quality === 0}
          onClick={() => onNextClick(target)}
          mx="auto"
          style={{ position: 'fixed', bottom: 8 }}
        >
          {t('create_group.save_btn', `To overview`)}
        </Button>
      </Center>
    </>
  )
}
