import { Center, Alert } from '@mantine/core'
import { IconAlertCircle } from '@tabler/icons'

const NoPaddocksFoundAlert = () => (
  <Center>
    <Alert icon={<IconAlertCircle size="1rem" />} title="No Paddocks found!" color="red">
      Sorry no data to show as there are no paddocks in this group.
    </Alert>
  </Center>
)

export default NoPaddocksFoundAlert
