import { Route, Routes } from 'react-router-dom'
import { CreateGrazingGroupPage } from './CreateGrazingGroupPage'
import { ListGrazingGroupsPage } from './ListGrazingGroupsPage'
import { ViewGrazingGroupPage } from './ViewGrazingGroupPage'

export const GrazingRoutes = () => {
  return (
    <Routes>
      <Route path="new" element={<CreateGrazingGroupPage />} />
      <Route path=":id" element={<ViewGrazingGroupPage />} />
      <Route path="" element={<ListGrazingGroupsPage />} />
    </Routes>
  )
}
