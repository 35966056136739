import * as Sentry from '@sentry/react'
import { AppProvider } from './providers/app'
import { AppRoutes } from './routes'
import 'mapbox-gl/dist/mapbox-gl.css'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js'
import DataProcessorStatus from './features/data-processor-status'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController
)

const App = () => {
  return (
    <AppProvider>
      <DataProcessorStatus />
      <AppRoutes />
    </AppProvider>
  )
}

export default Sentry.withProfiler(App)
