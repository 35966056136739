import { createContext, useContext } from 'react'
import { Paddock } from '@/features/paddocks/types/Paddock.types'
import { DefaultTarget, Target } from '@/features/paddock-groups/types/group.type'

type NewGroupContextProps = {
  groupName: string
  paddocks: Paddock[]
  target: Target
}

const emptyContext = {
  groupName: '',
  paddocks: [],
  target: {
    quantity: {
      pre: undefined,
      post: undefined,
      cut: undefined,
    },
    quality: DefaultTarget.quality,
  },
}

export const NewGroupContext = createContext<NewGroupContextProps>(emptyContext)

export const useNewGroupContext = () => {
  const context = useContext(NewGroupContext)
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserContextProvider')
  }

  return context
}
