import dayjs from 'dayjs'
import { Paddock } from './Paddock.types'
import { PaddockGroup } from './PaddockGroup.types'

export type UserActivityEventType =
  | 'com.farmote.api.PaddockMoved'
  | 'com.farmote.api.PaddockMoveDeleted'
  | 'com.farmote.api.ZoneGrazed'
  | 'com.farmote.api.ZoneGrazeDeleted'

type ExcludeSuffix<T, TSuffix extends string> = T extends `${string}${TSuffix}` ? never : T

export type DeletableUserActivityEventType = ExcludeSuffix<UserActivityEventType, 'Deleted'>

export type UserActivityLogDisplay = {
  eventName: string
  title: string
  subtitle: string
}

type PaddockMovedEventPayload = {
  farmId: string
  paddockId: string
  paddockGroupId: string
  previousPaddockGroupId: string
  reference: string
  date: string
  userId: string
}

type PaddockMoveDeletedEventPayload = {
  farmId: string
  paddockId: string
  from: string
  userId: string
  reference: string
}

type ZoneGrazedEventPayload = {
  end: string
  begin: string
  grazedGroupId: string
  userId: string
  farmId: string
  zoneId: string
}

type ZoneGrazeDeletedEventPayload = {
  grazedGroupId: string
  userId: string
  farmId: string
  zoneId: string
}

type ActivityLogEventTemplate<TEventType extends UserActivityEventType, TPayload> = {
  eventType: TEventType
  eventId: string
  payloadIdentifier: string
  traceParent: string
  eventTime: string
  payload: TPayload
}

export type UserActivityLogEvent =
  | ActivityLogEventTemplate<'com.farmote.api.PaddockMoved', PaddockMovedEventPayload>
  | ActivityLogEventTemplate<'com.farmote.api.PaddockMoveDeleted', PaddockMoveDeletedEventPayload>
  | ActivityLogEventTemplate<'com.farmote.api.ZoneGrazed', ZoneGrazedEventPayload>
  | ActivityLogEventTemplate<'com.farmote.api.ZoneGrazeDeleted', ZoneGrazeDeletedEventPayload>

export type DeletableActivityLogEvent = UserActivityLogEvent & {
  eventType: DeletableUserActivityEventType
}

export type DisplayableUserActivityLog = {
  display: UserActivityLogDisplay
  event: DeletableActivityLogEvent
}

export const formatUserActivityEvent =
  (paddocks: Paddock[], paddockGroups: PaddockGroup[]) =>
  (userActivityLog: DeletableActivityLogEvent[]): DisplayableUserActivityLog[] => {
    const zoneNames: Record<string, string> = Object.fromEntries(
      paddocks.map((p) => [p.zoneId!, p.name])
    )
    const paddockNames: Record<string, string> = Object.fromEntries(
      paddocks.map((p) => [p.id!, p.name])
    )
    const paddockGroupNames: Record<string, string> = Object.fromEntries(
      paddockGroups.map((p) => [p.id!, p.name])
    )

    const formatOne = (evt: DeletableActivityLogEvent): UserActivityLogDisplay => {
      switch (evt.eventType) {
        case 'com.farmote.api.PaddockMoved':
          return {
            eventName: 'Paddock Move',
            title: `${paddockNames[evt.payload.paddockId]} Moved from ${
              paddockGroupNames[evt.payload.previousPaddockGroupId]
            } to ${paddockGroupNames[evt.payload.paddockGroupId]}`,
            subtitle: `${dayjs(evt.payload.date).format('DD/MM/YYYY')}`,
          }
        case 'com.farmote.api.ZoneGrazed':
          return {
            eventName: 'Grazing',
            title: `${zoneNames[evt.payload.zoneId]} Grazed`,
            subtitle: `${dayjs(evt.payload.begin).format('DD/MM/YYYY')} - ${dayjs(
              evt.payload.end
            ).format('DD/MM/YYYY')}`,
          }
        default:
          return ((_x: never) => {
            throw Error('invalid case')
          })(evt) // to defend against new cases
      }
    }

    return userActivityLog.map((evt) => ({ display: formatOne(evt), event: evt }))
  }
