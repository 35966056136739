export function getRGB(rgbStr: string): number[] {
  return rgbStr
    .replace(/rgba\(([\d,\s.]+)\)/, '$1')
    .split(',')
    .map(parseFloat)
    .slice(0, 3)
}

export function hexToRgb(hex: string): {
  r: number
  g: number
  b: number
} {
  // Remove the "#" at the beginning of the hex string
  hex = hex.substring(1)

  // Convert the hex string to RGB values
  var r = parseInt(hex.substring(0, 2), 16)
  var g = parseInt(hex.substring(2, 4), 16)
  var b = parseInt(hex.substring(4, 6), 16)

  // Return the RGB values as an object
  return { r: r, g: g, b: b }
}

export function hexToRgbStr(hex: string): string {
  const { r, g, b } = hexToRgb(hex)
  return `rgb(${r}, ${g}, ${b})`
}
