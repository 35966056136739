import { GRAZING_SERVICE_URL } from '@/config'
import { axios } from '@/lib/axios'
import { queryClient } from '@/lib/react-query'
import { useMutation } from '@tanstack/react-query'
import { Herd } from './types'

const deleteHerd = (farmId: number, herdId: string) => {
  return axios.delete(`${GRAZING_SERVICE_URL}/api/farms/${farmId}/herds/${herdId}`)
}

export const useDeleteHerd = (farmId?: string) => {
  return useMutation({
    mutationFn: (herdId: string) => deleteHerd(Number(farmId!), herdId),
    onMutate: async (deletedHerdId) => {
      await queryClient.cancelQueries(['herds', farmId])

      const previousHerds = queryClient.getQueryData<Herd[]>(['herds', farmId])

      queryClient.setQueryData(
        ['herds', farmId],
        previousHerds?.filter((h) => h.id !== deletedHerdId)
      )
    },
  })
}
