import { Group, Stack, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'

type PaddockGroupHeaderProps = {
  title: string
  area: number
}

export const PaddockGroupHeader = ({ title, area }: PaddockGroupHeaderProps) => {
  const { t } = useTranslation('paddock-group')

  return (
    <Group position="apart" p="sm">
      <Text fz="lg" fw={600}>
        {title}
      </Text>
      <Stack spacing={0}>
        <Text fz="xs" color="gray.6">
          {t('headers.surface', 'Surface area')}
        </Text>
        <Text fz="xm" fw={700}>
          {area} ha
        </Text>
      </Stack>
    </Group>
  )
}
