import { doc } from '@firebase/firestore'
import { useEffect, useState } from 'react'
import { DocumentData, getDoc } from 'firebase/firestore'
import { firestore } from '@/lib/firebase'

export const useUserProfile = (userId: string | undefined) => {
  const [userProfile, setUserProfile] = useState<DocumentData>()

  useEffect(() => {
    const loadUserProfile = async () => {
      try {
        const profileRef = doc(firestore, 'users', userId!)
        const profileDoc = await getDoc(profileRef)
        setUserProfile(profileDoc.data())
      } catch (e) {
        console.error(e)
      }
    }

    if (userId) {
      loadUserProfile()
    }
  }, [userId])

  return userProfile
}
