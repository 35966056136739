import { CoverDatum } from '@/features/paddock-groups/types/graph.types'
import { QuantityTarget } from '@/features/paddock-groups/types/group.type'
import { hexToRgbStr } from '@/utils/rgba'
import { times } from 'lodash'

const targetLine = (dataLength: number, target: number, hexColor: string) => ({
  type: 'line' as const,
  data: times(dataLength, () => target),
  borderColor: [hexToRgbStr(hexColor)],
  borderWidth: 1.5,
  pointRadius: 0,
})

const preGrazingLine = (dataLength: number, preTarget: number) =>
  targetLine(dataLength, preTarget, '#E64980')
const postGrazingLine = (dataLength: number, postTarget: number) =>
  targetLine(dataLength, postTarget, '#E64980')

const feedWedgeLine = (growthsLength: number, targets: QuantityTarget) => ({
  type: 'line' as const,
  data: times(
    growthsLength,
    (i) => targets.pre! - (i * (targets.pre! - targets.post!)) / growthsLength
  ),
  borderColor: [hexToRgbStr('#519552')],
  borderWidth: 1.5,
  pointRadius: 0,
})

const growthBars = (dataWithLabels: CoverDatum[]) => ({
  type: 'bar' as const,
  data: dataWithLabels.map((d) => d.cover),
  backgroundColor: [hexToRgbStr('#8DC18E')],
  borderColor: [hexToRgbStr('#8DC18E')],
  hoverBackgroundColor: [hexToRgbStr('#519552')],
  borderWidth: 1,
  borderRadius: 5,
  barThickness: 40,
})

export const getGraphData = (
  dataWithLabels: CoverDatum[],
  targets: QuantityTarget,
  growthsLength: number
) => ({
  labels: dataWithLabels.map((d) => d.label),
  datasets: [
    preGrazingLine(dataWithLabels.length, targets.pre!),
    postGrazingLine(dataWithLabels.length, targets.post!),
    feedWedgeLine(growthsLength, targets),
    growthBars(dataWithLabels),
  ],
})
