import { Avatar } from '@mantine/core'
import ColorHash from 'color-hash'

const colorHash = new ColorHash()

type UserAvatarProps = {
  email: string
  photoUrl?: string | null
}
export const UserAvatar = ({ email, photoUrl }: UserAvatarProps) => {
  if (photoUrl) {
    return <Avatar radius="xl" src={photoUrl} />
  }

  return (
    <Avatar radius="xl" color={colorHash.hex(email)} style={{ textTransform: 'uppercase' }}>
      {email[0]}
    </Avatar>
  )
}
