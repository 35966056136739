import { useUserContext } from '@/providers/user'
import { Select } from '@mantine/core'

export const Farms = () => {
  const { farms: allFarms, farmId, setFarmId } = useUserContext()

  if (!allFarms || allFarms.length <= 1) {
    return <></>
  }

  const farms = allFarms!.map((f) => ({ label: f.name, value: f.id.toString() }))

  return (
    <Select
      data={farms}
      defaultValue={farms[0].label}
      value={farmId}
      onChange={setFarmId}
      transitionProps={{ transition: 'pop-top-left', duration: 80, timingFunction: 'ease' }}
    />
  )
}
