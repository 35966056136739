import { GRAZING_SERVICE_URL } from '@/config'
import { axios } from '@/lib/axios'
import { queryClient } from '@/lib/react-query'
import { useMutation } from '@tanstack/react-query'
import { Herd } from './types'

type CreateHerdData = Omit<Herd, 'id' | 'farmId'>
const createHerd = async (farmId: number, values: CreateHerdData) => {
  const { data } = await axios.post<Herd>(
    `${GRAZING_SERVICE_URL}/api/farms/${farmId}/herds`,
    values
  )

  return data
}

export const useCreateHerd = (farmId?: string) => {
  return useMutation({
    mutationFn: (data: CreateHerdData) => createHerd(Number(farmId), data),
    onSuccess: (newHerd) => {
      const previousHerds = queryClient.getQueryData<Herd[]>(['herds', farmId])
      queryClient.setQueryData(['herds', farmId], [...(previousHerds || []), newHerd])
    },
  })
}
