import { PaddockGroupType } from '@/features/paddocks/types/PaddockGroup.types'
import { Badge } from '@mantine/core'

type PaddockGroupBadgeProps = {
  type: PaddockGroupType
}

export const PaddockGroupBadge = ({ type }: PaddockGroupBadgeProps) => {
  const getBadgeColor = (type: PaddockGroupType): string => {
    switch (type) {
      case PaddockGroupType.Grazing:
        return 'green.8'
      default:
        return 'gray.8'
    }
  }

  return (
    <Badge color={getBadgeColor(type)} ml="auto">
      {type}
    </Badge>
  )
}
