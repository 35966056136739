import { createStyles, Navbar } from '@mantine/core'
import { User } from './User'
import React from 'react'
import { FaHome, FaList, FaTractor, FaCheckCircle } from 'react-icons/fa'
import { ThemeIcon, UnstyledButton, Group, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const useStyles = createStyles((theme) => ({
  navbar: {
    paddingTop: '0 !important',
  },

  section: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    marginBottom: theme.spacing.md,

    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
      }`,
    },
  },
}))

interface NavBarLinkProps {
  icon: React.ReactNode
  color: string
  label: string
  to: string
}

export const NavBarLink = ({ icon, color, label, to }: NavBarLinkProps) => {
  const { t } = useTranslation('common')

  return (
    <UnstyledButton
      component={Link}
      to={to}
      sx={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

        '&:hover': {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
      })}
    >
      <Group>
        <ThemeIcon color={color} variant="light">
          {icon}
        </ThemeIcon>

        <Text size="sm">{t(`navbar.${label}`, label)}</Text>
      </Group>
    </UnstyledButton>
  )
}

const links = [
  { icon: <FaHome size={16} />, color: 'blue', label: 'Dashboard', to: '/dashboard' },
  { icon: <FaList size={16} />, color: 'violet', label: 'Paddocks', to: '/paddocks' },
  { icon: <FaTractor size={16} />, color: 'pink', label: 'Cutting', to: '/cutting' },
  { icon: <FaTractor size={16} />, color: 'green', label: 'Grazing', to: '/grazing' },
  // { icon: <FaCheckCircle size={16} />, color: 'cyan', label: 'Actions', to: '/actions' },
  { icon: <FaCheckCircle size={16} />, color: 'orange', label: 'Herds', to: '/herds' },
]

type AppNavbarProps = {
  opened: boolean
}

export const AppNavbar = ({ opened }: AppNavbarProps) => {
  const { classes } = useStyles()

  return (
    <Navbar
      width={{ sm: 300 }}
      p="md"
      hiddenBreakpoint="sm"
      hidden={!opened}
      className={classes.navbar}
    >
      <Navbar.Section grow mt="xs">
        {links.map((link) => (
          <NavBarLink {...link} key={link.label} />
        ))}
      </Navbar.Section>
      <Navbar.Section>
        <User />
      </Navbar.Section>
    </Navbar>
  )
}
