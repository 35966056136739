import { useTranslation } from 'react-i18next'
import { Button, Stack, TextInput } from '@mantine/core'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useNewGroupContext } from '@/providers/newCuttingGroup'

type AddNameProps = {
  isCuttingGroup?: boolean
  onNextClick: (name: string) => void
}

export const AddName = ({ isCuttingGroup = false, onNextClick }: AddNameProps) => {
  const { t } = useTranslation('paddock-group')
  const navigate = useNavigate()
  const { groupName: existingGroupName } = useNewGroupContext()
  const [groupName, setGroupName] = useState(existingGroupName)

  return (
    <>
      <TextInput
        label={t('form.name_label', 'Group name')}
        value={groupName}
        onChange={(e) => setGroupName(e.target.value)}
        required
      />
      <Stack mt="lg">
        <Button
          type="submit"
          color="green.9"
          disabled={(groupName ?? '').trim().length === 0}
          onClick={() => onNextClick(groupName)}
        >
          {t('create_group.save_btn', 'Select Paddocks')}
        </Button>
        <Button
          type="reset"
          variant="outline"
          onClick={() => navigate(isCuttingGroup ? '/cutting' : '/grazing')}
        >
          {t('create_group.cancel_btn', 'Cancel')}
        </Button>
      </Stack>
    </>
  )
}
