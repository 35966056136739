import { GRAZING_SERVICE_URL } from '@/config'
import { axios } from '@/lib/axios'
import { useMutation, useQuery } from '@tanstack/react-query'
import { PaddockOrder } from './types'
import { queryClient } from '@/lib/react-query'
import { auth } from '@/lib/firebase'
import { getOrderedPaddockNames } from '@/utils/paddockOrderUtil'

const getPaddockOrder = async (farmId: number, paddockGroupId: number): Promise<PaddockOrder> => {
  const token = await auth.currentUser?.getIdToken()
  const { data } = await axios.get(
    `${GRAZING_SERVICE_URL}/api/rotation/farms/${farmId}/paddock-groups/${paddockGroupId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return data
}

export const usePaddockOrder = (farmId: number, paddockGroupId: number) => {
  return useQuery({
    queryKey: ['paddockOrder', `${farmId}_${paddockGroupId}`],
    queryFn: async () => {
      const paddockOrder = await getPaddockOrder(farmId, paddockGroupId)
      const orderedPaddocks = getOrderedPaddockNames(paddockOrder)
      return orderedPaddocks
    },
  })
}

const updatePaddockOrder = async (
  farmId: number,
  paddockGroupId: number,
  values: PaddockOrder
): Promise<PaddockOrder> => {
  const token = await auth.currentUser?.getIdToken()
  const { data } = await axios.post(
    `${GRAZING_SERVICE_URL}/api/rotation/farms/${farmId}/paddock-groups/${paddockGroupId}`,
    {
      ...values,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return data
}

export const useUpdatePaddockOrder = (farmId: number, paddockGroupId: number) => {
  return useMutation({
    mutationFn: (data: PaddockOrder) => updatePaddockOrder(farmId, paddockGroupId, data),
    onSuccess: (newPaddockOrder) => {
      queryClient.setQueryData(
        ['paddockOrder', `${farmId}_${paddockGroupId}`],
        getOrderedPaddockNames(newPaddockOrder)
      )
    },
  })
}
