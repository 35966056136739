import { Center, Button, Alert } from '@mantine/core'
import { t } from 'i18next'
import { usePaddockGroupGrowths, usePaddockGrowths } from '@/features/api/growthsApi'
import { useUserContext } from '@/providers/user'
import { useParams } from 'react-router-dom'
import { useDisclosure } from '@mantine/hooks'
import { usePaddockOrder } from '../api/paddockOrderApi'
import { PaddockOrder } from './PaddockOrder'
import { ReportGrazing } from './report-grazing'
import { GrazingGroupGraph } from './graph/GrazingGroupGraph'
import { RecommendedGrazingGraph } from './graph/RecommendedGrazingGraph'
import { PaddockGroup } from '@/features/paddocks/types/PaddockGroup.types'
import { PaddockGroupMetrics } from '@/components/PaddockGroupMetrics'
import { IconAlertCircle } from '@tabler/icons'
import { Paddock } from '@/features/paddocks/types/Paddock.types'
import { DefaultTarget } from '@/features/paddock-groups/types/group.type'

type GrowthsProps = {
  paddockGroup: PaddockGroup
}

export const Growths = ({ paddockGroup }: GrowthsProps) => {
  const { id } = useParams()
  const { farmId } = useUserContext()

  const { data: paddockGrowths, isLoading } = usePaddockGrowths(
    Number(id),
    paddockGroup.targets.post ?? DefaultTarget.quantity.post,
    farmId
  )

  const { data: paddockGroupGrowths } = usePaddockGroupGrowths(
    Number(id),
    paddockGroup.targets.post,
    farmId
  )

  const paddockOrder = usePaddockOrder(Number(farmId), Number(id)).data ?? []
  const [opened, { open, close }] = useDisclosure(false)

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!paddockGrowths || !paddockGroup) {
    return (
      <Alert icon={<IconAlertCircle size="1rem" />} title="Bummer!" color="red">
        Sorry no data found! Please come back later.
      </Alert>
    )
  }

  const getPaddockWithGrowth = (p: Paddock): Paddock => {
    const pg = paddockGrowths.find((pg) => pg.paddock_id === p.id)
    return {
      ...p,
      availableFeed: pg?.available_feed ?? 0,
      effectiveArea: pg?.effective_area ?? 0,
      zoneId: pg?.zone_id,
    }
  }

  const suggestedPaddockName = paddockOrder[0] ?? paddockGrowths[0]?.zone_name!
  const suggestedPaddock =
    paddockGroup.paddocks.find((p) => p.name === suggestedPaddockName) ?? paddockGroup.paddocks[0]
  const suggestedPaddockWithGrowth = getPaddockWithGrowth(suggestedPaddock)
  const paddocksWithGrowth = paddockGroup.paddocks.map((p) => getPaddockWithGrowth(p))

  return (
    <>
      <ReportGrazing suggestedPaddock={suggestedPaddockWithGrowth} paddocks={paddocksWithGrowth} />
      {paddockOrder.length === 0 ? undefined : (
        <GrazingGroupGraph
          growths={paddockGrowths}
          paddockOrder={paddockOrder}
          targets={paddockGroup.targets.pre ? paddockGroup.targets : DefaultTarget.quantity}
        />
      )}
      <Center>
        <Button variant="default" mb="xl" mt="lg" onClick={open}>
          {t('order', 'Change paddock order')}
        </Button>
      </Center>
      <PaddockOrder
        allPaddocks={paddockGrowths.map((p) => p.zone_name!)}
        opened={opened}
        onClose={close}
      />
      <RecommendedGrazingGraph
        growths={paddockGrowths}
        targets={paddockGroup.targets.pre ? paddockGroup.targets : DefaultTarget.quantity}
      />
      {paddockGroupGrowths && <PaddockGroupMetrics growths={paddockGroupGrowths} />}
    </>
  )
}
