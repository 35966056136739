import {
  ActionIcon,
  AppShell,
  BackgroundImage,
  Center,
  Col,
  Flex,
  Grid,
  Header,
  Title,
  UnstyledButton,
} from '@mantine/core'
import { useState } from 'react'
import { IconChevronLeft } from '@tabler/icons'
import { AppHeader } from './AppHeader'
import { AppNavbar } from './AppNavbar'
import MapHeaderBackground from './assets/background.jpg'

interface LayoutProps {
  backgroundImg: string
  children: React.ReactNode
}

export const Layout = ({ backgroundImg, children }: LayoutProps) => {
  const [opened, setOpened] = useState(false)
  const toggleNavbar = () => {
    setOpened((o) => !o)
  }

  return (
    <AppShell
      padding={0}
      navbarOffsetBreakpoint="sm"
      navbar={<AppNavbar opened={opened} />}
      header={<AppHeader opened={opened} toggle={toggleNavbar} />}
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
          paddingTop: 40,
        },
      })}
    >
      <BackgroundImage
        src={backgroundImg}
        style={{
          backgroundAttachment: 'fixed',
          height: '100%',
        }}
      >
        {children}
      </BackgroundImage>
    </AppShell>
  )
}

type SimpleLayoutProps = {
  children: React.ReactNode
  title: string
  rightIcon?: React.ReactNode
  backClick: () => void
}

export const SimpleLayout = ({ children, title, rightIcon, backClick }: SimpleLayoutProps) => {
  return (
    <>
      <Header height={60} p="xs" withBorder={false}>
        <Grid>
          <Col span="content">
            <UnstyledButton onClick={backClick}>
              <IconChevronLeft />
            </UnstyledButton>
          </Col>
          <Col span="auto">
            <Center component={Title} order={4}>
              {title}
            </Center>
          </Col>
          {!!rightIcon && <Col span="content">{rightIcon}</Col>}
        </Grid>
      </Header>

      {children}
    </>
  )
}

type MapHeaderLayoutProps = {
  children: React.ReactNode
  rightIcon?: React.ReactNode
  backClick: () => void
}

export const MapHeaderLayout = ({ children, backClick, rightIcon }: MapHeaderLayoutProps) => {
  const [opened, setOpened] = useState(false)
  const toggleNavbar = () => {
    setOpened((o) => !o)
  }

  return (
    <AppShell
      padding={0}
      navbarOffsetBreakpoint="sm"
      navbar={<AppNavbar opened={opened} />}
      header={<AppHeader opened={opened} toggle={toggleNavbar} />}
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
          paddingTop: 40,
        },
      })}
    >
      <BackgroundImage
        src={MapHeaderBackground}
        style={{
          height: 240,
        }}
      >
        <Flex justify="space-between" p="xl">
          <ActionIcon size="xl" radius="xl" variant="light" onClick={backClick}>
            <IconChevronLeft size={24} />
          </ActionIcon>
          {rightIcon}
        </Flex>
      </BackgroundImage>
      {children}
    </AppShell>
  )
}
