import { Herd, useHerds } from '@/features/herds/api'
import { useUserContext } from '@/providers/user'
import {
  Button,
  Center,
  Checkbox,
  Flex,
  LoadingOverlay,
  NumberInput,
  Select,
  Text,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useReportGrazing } from '../../api/grazingApi'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { usePaddockOrder, useUpdatePaddockOrder } from '../../api/paddockOrderApi'
import { fromPairs } from 'lodash'
import { Paddock } from '@/features/paddocks/types/Paddock.types'
import { usePphProcessStatus, useUpdatePphProcessorStatus } from '@/features/data-processor-status'

interface ReportDialogProps {
  suggestedPaddock: Paddock
  paddocks: Paddock[]
}

export const ReportDialog = ({ suggestedPaddock, paddocks }: ReportDialogProps) => {
  const { id } = useParams()
  const { farmId } = useUserContext()
  const { t } = useTranslation('report-grazing')

  const paddockOrder = usePaddockOrder(Number(farmId), Number(id)).data ?? []
  const reportGrazingMutation = useReportGrazing(Number(farmId))
  const updatePaddockOrder = useUpdatePaddockOrder(Number(farmId), Number(id))
  const { data: processingStatus } = usePphProcessStatus(farmId)
  const updatePphProcessorStatus = useUpdatePphProcessorStatus(farmId)

  const { data: herds, isLoading: isHerdLoading } = useHerds(farmId)

  const [selectedPaddock, setSelectedPaddock] = useState<Paddock>(suggestedPaddock)
  const [selectedHerd, setSelectedHerd] = useState<Herd | undefined>()
  const [grazedHours, setGrazedHours] = useState<number>(0)
  const [feedIntake, setFeedIntake] = useState<number>(0)
  const [feedLeft, setFeedLeft] = useState<number>(0)
  const [isGrazingFinished, setIsGrazingFinished] = useState(true)

  useEffect(() => {
    if (!selectedHerd) {
      return
    }
    const { dairyCowsFeedIntake, youngCattleFeedIntake, dryCowsFeedIntake } = selectedHerd

    const { dairyCowsAmount, youngCattleAmount, dryCowsAmount } = selectedHerd

    const dairyIntake = dairyCowsAmount * grazedHours * dairyCowsFeedIntake
    const dryIntake = dryCowsAmount * grazedHours * dryCowsFeedIntake
    const youngIntake = youngCattleAmount * grazedHours * youngCattleFeedIntake

    setFeedIntake(dairyIntake + dryIntake + youngIntake)
  }, [grazedHours, selectedHerd])

  useEffect(() => {
    const availableFeed = selectedPaddock?.availableFeed ?? 0
    setFeedLeft(availableFeed - feedIntake)
  }, [feedIntake, selectedPaddock])

  const handlePaddockSelect = (paddockId: string) => {
    const paddock = paddocks.find((p) => p.id === Number(paddockId))!
    setSelectedPaddock(paddock)
  }

  const handleHerdSelect = (herdId: string) => {
    const selectedHerd = herds?.find((h) => h.id === herdId)
    setSelectedHerd(selectedHerd)
  }

  const handleReportClick = async () => {
    if (paddockOrder.length > 0 && isGrazingFinished) {
      const currentPaddock = paddockOrder.shift()
      paddockOrder.push(currentPaddock!)

      await updatePaddockOrder.mutateAsync(
        fromPairs(paddockOrder.map((key, index) => [key, index]))
      )
    }

    await updatePphProcessorStatus.mutateAsync('in-progress')
    await reportGrazingMutation.mutateAsync([
      {
        begin: dayjs().format('DD/MM/YYYY'),
        end: dayjs().format('DD/MM/YYYY'),
        zone_id: selectedPaddock.zoneId!,
        zone_name: selectedPaddock.zoneName,
        dry_matter: feedLeft / (selectedPaddock?.effectiveArea ?? 1),
      },
    ])
  }

  if (isHerdLoading) {
    return <></>
  }

  const herdIntakeMessage = (): string => {
    let intake = []
    if (!selectedHerd) {
      return ''
    }
    const { dairyCowsFeedIntake, youngCattleFeedIntake, dryCowsFeedIntake } = selectedHerd

    const { dairyCowsAmount, youngCattleAmount, dryCowsAmount } = selectedHerd

    if (dairyCowsAmount > 0) {
      intake.push(`${dairyCowsAmount} dairy cows (${dairyCowsFeedIntake} kgDM/h)`)
    }
    if (dryCowsAmount > 0) {
      intake.push(`${dryCowsAmount} dairy cows (${dryCowsFeedIntake} kgDM/h)`)
    }
    if (youngCattleAmount > 0) {
      intake.push(`${youngCattleAmount} dairy cows (${youngCattleFeedIntake} kgDM/h)`)
    }
    return intake.join(', ')
  }

  return (
    <Flex gap={16} direction="column">
      <Select
        label="Paddock"
        description={`available feed: ${selectedPaddock.availableFeed} kg`}
        defaultValue={selectedPaddock.id.toString()}
        data={paddocks.map((p) => ({ value: p.id.toString(), label: p.name }))}
        value={selectedPaddock.id.toString()}
        onChange={handlePaddockSelect}
        withAsterisk
      />
      <Select
        label="Herd"
        description={t('form.cows_intake', `${selectedHerd ? herdIntakeMessage() : ''}`)}
        data={herds!.map((h) => ({ value: h.id, label: h.name }))}
        value={selectedHerd?.id}
        onChange={handleHerdSelect}
        withAsterisk
      />
      <NumberInput
        label={t('form.cows_grazing_time', 'How long do you think the cows ate today?')}
        description="hrs"
        step={1}
        min={0}
        defaultValue={0}
        stepHoldDelay={500}
        stepHoldInterval={100}
        onChange={(value) => setGrazedHours(Number(value))}
      />
      <NumberInput
        label={t('form.feed_intake_label', 'Feed intake')}
        description={t('form.feed_intake_desc', 'kgDM')}
        precision={2}
        step={0.5}
        min={0}
        value={feedIntake}
        stepHoldDelay={500}
        stepHoldInterval={100}
        onChange={(value) => setFeedIntake(Number(value))}
        withAsterisk
      />
      <Text size="sm">Remaining feed left {feedLeft} kg</Text>
      <Checkbox
        label="Finished Grazing"
        checked={isGrazingFinished}
        onChange={(e) => setIsGrazingFinished(e.currentTarget.checked)}
        color="green.9"
      />
      <Center>
        <Button
          mb="xl"
          mt="lg"
          color="green.9"
          onClick={handleReportClick}
          disabled={processingStatus === 'in-progress'}
        >
          {t('report', 'Report')}
        </Button>
      </Center>
      <LoadingOverlay visible={processingStatus === 'in-progress'} overlayBlur={2} />
    </Flex>
  )
}
