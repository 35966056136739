import { Card, Group, Text } from '@mantine/core'
import { FaArrowCircleDown, FaArrowCircleUp } from 'react-icons/fa'

type MetricCardProps = {
  title: string
  value: number
  unit?: string | null
  variation?: {
    value: number
    label: string
  }
}

export const MetricCard = ({ title, value, unit, variation }: MetricCardProps) => {
  const variationIcon =
    variation && variation.value > 0 ? (
      <FaArrowCircleUp size={20} color="green" />
    ) : (
      <FaArrowCircleDown size={20} color="red" />
    )

  return (
    <Card shadow="sm" radius="md" withBorder bg="gray.0" miw={155}>
      <Card.Section withBorder p="xs">
        <Text align="center" size="md">
          {title}
        </Text>
        <Text align="center" size={40}>
          {value}
        </Text>
        <Text align="center" size="md" color="dimmed">
          {unit}
        </Text>
      </Card.Section>
      {variation && (
        <>
          <Card.Section pt="xs">
            <Group position="center">
              <Text size="md" align="center">
                {variation.value}
              </Text>
              {variationIcon}
            </Group>
            <Text align="center" size="xs" color="dimmed">
              {variation.label}
            </Text>
          </Card.Section>
        </>
      )}
    </Card>
  )
}
